import React, { useEffect, useRef, useState } from "react";
import MessageBox from "./MessageBox";
import ReceivedMessage from "./ReceivedMessage";
import SentMessage from "./SentMessage";
import userImgPlaceholder from "./../../assets/images/chat-cici.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPatientInfo } from "../../helpers/authManager";
import {
  getAIChatMessages_Ajax,
  getAIChatRoom_Ajax,
  getSmartScanCaseReport_Ajax,
  saveAIChatMessages_Ajax
} from "../../helpers/requests";
import {
  getLocalTimezoneOffset,
  handleApiErrors,
  isEmptyArray,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  CICILinks,
  IntervalTimes,
  LinkTypes,
  Pagination
} from "../../constants";
import { sendMessageToCICI_Ajax } from "../../helpers/requestsExternal";
import moment from "moment";
import { getSmartScanInitialMessageForCICI } from "../../helpers/AIChatUtils";
import { ReactTyped } from "react-typed";
import { getBaseUrl } from "../../ApplicationSettings";
import { startSpeaking, stopSpeaking } from "../../helpers/TextToSpeech";

const ChatSection = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [chatRoomId, setChatRoomId] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesGroupedByDate, setMessagesGroupedByDate] = useState([]);
  const patientInfo = getPatientInfo();
  const exisitngMessagesRef = useRef([]);
  const [smartScanCaseReport, setSmartScanCaseReport] = useState();
  const [isWaitingForReply, setIsWaitingForReply] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [messagesToType, setMessagesToType] = useState([]);
  const replyMessagesObjRef = useRef([]);
  const [currentMessagesToTypeIndex, setCurrentMesssagesToTypeIndex] =
    useState(-1);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentDateString, setCurrentDateString] = useState("");
  const [playingMsgId, setPlayingMsgId] = useState(0);

  const messageTypes = {
    sent: "sent",
    received: "received"
  };

  const chatWrapperRef = useRef();
  const chatListDivRef = useRef();

  useEffect(() => {
    if (!chatListDivRef.current || !chatWrapperRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (isAITyping) {
        scrollToBottom();
      }
    });

    resizeObserver.observe(chatListDivRef.current);
    return () => resizeObserver.disconnect();
  }, [chatListDivRef, chatWrapperRef, isAITyping]);

  useEffect(() => {
    if (state?.patientId > 0) {
      getSmartScanCaseReport(state.patientId, state.smartScanId);
    }
  }, [state]);

  useEffect(() => {
    if (smartScanCaseReport) {
      getAIChatRoom();
    }
  }, [smartScanCaseReport]);

  useEffect(() => {
    if (chatRoomId > 0) {
      getAIChatMessages(chatRoomId);
    }
  }, [chatRoomId]);

  useEffect(() => {
    if (messages.length > 0) {
      processMessages(messages);
    }
  }, [messages]);

  function getSmartScanCaseReport(patientId, smartScanId = 0) {
    let params = {
      includeDetails: true,
      patId: patientId,
      shouldGetLatestSmartScanCaseReport: smartScanId > 0 ? false : true,
      includeToothDetails: true
    };

    dispatch(showLoadingSpinner());
    getSmartScanCaseReport_Ajax(
      smartScanId,
      params,
      function (resposne) {
        dispatch(hideLoadingSpinner());

        if (resposne?.success && resposne?.data?.smartScanCaseReportVM) {
          setSmartScanCaseReport(resposne?.data?.smartScanCaseReportVM);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }
  function processMessages(messages) {
    let newProcessedMessages = [];
    messages.forEach((msg) => {
      let processedMessage = {
        ...msg
      };

      let sentTime = msg?.createdOn;
      if (sentTime) {
        processedMessage.date = moment(sentTime).format("dddd MMM DD, YYYY");
        processedMessage.time = moment(sentTime).format("LT");
      } else {
        processedMessage.date = moment().format("dddd MMM DD, YYYY");
        processedMessage.time = moment().format("LT");
      }

      if (msg?.senderUserId > 0) {
        processedMessage.type = messageTypes.sent;
        processedMessage.userImg = patientInfo?.photoId;
      } else {
        processedMessage.type = messageTypes.received;
        processedMessage.userImg = userImgPlaceholder;
      }

      newProcessedMessages.push(processedMessage);
    });

    if (newProcessedMessages.length > 0) {
      exisitngMessagesRef.current = [...newProcessedMessages];
      let msgGroupedByDate = newProcessedMessages.groupBy("date");
      setMessagesGroupedByDate(msgGroupedByDate);
    }
  }

  function getAIChatRoom() {
    dispatch(showLoadingSpinner());
    setIsProcessing(true);

    getAIChatRoom_Ajax(
      patientInfo.userId,
      function (response) {
        if (response?.success && response?.data?.chatRoom?.aiChatRoomId > 0) {
          setChatRoomId(response?.data?.chatRoom?.aiChatRoomId);
        } else if (!response.success && response.message) {
          showAlertDialouge("Error", response.message);
        }

        dispatch(hideLoadingSpinner());
      },
      function (err) {
        handleApiErrors(err);
        dispatch(hideLoadingSpinner());
        setIsProcessing(false);
      }
    );
  }

  function scrollToBottom(height) {
    if (!height) {
      height = chatWrapperRef.current.scrollHeight;
    }

    chatWrapperRef.current.scrollTo(0, height);
  }

  function handleScrollOnGettingOldMessage() {
    if (exisitngMessagesRef.current.length <= Pagination.AIChatItemPerPage) {
      scrollToBottom();
    } else {
      scrollToBottom(50);
    }
  }

  function getAIChatMessages(chatRoomId) {
    if (chatRoomId > 0) {
      dispatch(showLoadingSpinner());
      setIsProcessing(true);
      let pageOffset = 0;

      if (exisitngMessagesRef?.current?.length > 0) {
        pageOffset = exisitngMessagesRef.current.length;
      }

      let params = {
        pageOffset: pageOffset,
        itemPerPage: Pagination.AIChatItemPerPage,
        localTimezoneOffset: getLocalTimezoneOffset(),
        sendInitialMessagesOnly: true
      };

      if (smartScanCaseReport?.smartScanId) {
        params.smartScanId = smartScanCaseReport.smartScanId;
      }

      getAIChatMessages_Ajax(
        chatRoomId,
        params,
        function (response) {
          if (
            response.success &&
            !isEmptyArray(response?.data?.chatMessageVMs)
          ) {
            let result = response.data.chatMessageVMs;
            let existingMessage = exisitngMessagesRef.current;

            setMessages([...result, ...existingMessage]);
            setTimeout(handleScrollOnGettingOldMessage, 50);
          } else if (
            !response.success &&
            messages.length == 0 &&
            smartScanCaseReport
          ) {
            sendMessage(getSmartScanInitialMessageForCICI(smartScanCaseReport));
          }

          setIsProcessing(false);
          dispatch(hideLoadingSpinner());
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          setIsProcessing(false);
          handleApiErrors(err);
        }
      );
    }
  }

  function getOnDemandCallPageLink() {
    let params = {};
    if (smartScanCaseReport?.smartScanId) {
      params.smartScanId = smartScanCaseReport.smartScanId;
    }

    if (smartScanCaseReport?.patId) {
      params.patientId = smartScanCaseReport.patId;
    }

    let url = getBaseUrl() + "/see-dentist?" + $.param(params);

    return url;
  }

  function getLink(linkType) {
    if (linkType == LinkTypes.SeeDentistNow) {
      return getOnDemandCallPageLink();
    }
  }

  function convertLinks(message) {
    if (message) {
      for (let [key, value] of Object.entries(CICILinks)) {
        let url = getLink(value);

        if (url) {
          let element = `<a href="${url}">${key.toString()}</a>`;
          message = message.replace(key, element);
        }
      }

      return message;
    }
  }

  function saveMessages(messageToSave, messageType) {
    if (messageToSave && messageType) {
      let messageData = messageToSave.map((message) => {
        let msgObj = {};

        msgObj.smartScanId = smartScanCaseReport?.smartScanId;
        msgObj.message = convertLinks(message);

        if (messageType == messageTypes.sent) {
          msgObj.senderUserId = patientInfo.userId;
        }

        return msgObj;
      });

      let currentMsg = [...exisitngMessagesRef.current];

      saveAIChatMessages_Ajax(
        chatRoomId,
        JSON.stringify(messageData),
        function (response) {
          if (response.success && !isEmptyArray(response?.data?.chatMessages)) {
            let newChatMessages = response.data.chatMessages;
            if (messageType == messageTypes.received) {
              startTypingReplyMessages(newChatMessages);
            } else {
              setMessages([...currentMsg, ...newChatMessages]);
            }
          } else if (!response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function startTypingReplyMessages(messages) {
    if (messages.length > 0) {
      setIsAITyping(true);
      let messagesToType = messages.map((msg) => msg.message);
      setMessagesToType([...messagesToType]);
      replyMessagesObjRef.current = [...messages];
      setCurrentMesssagesToTypeIndex(0);
    }
  }

  useEffect(() => {
    if (
      currentMessagesToTypeIndex >= 0 &&
      currentMessagesToTypeIndex <= messagesToType.length - 1 &&
      messagesToType.length > 0
    ) {
      setCurrentMessage(messagesToType[currentMessagesToTypeIndex]);
    } else if (
      currentMessagesToTypeIndex >= messagesToType.length &&
      messagesToType.length > 0
    ) {
      setCurrentMesssagesToTypeIndex(-1);
      setIsAITyping(false);
      setMessagesToType([]);
      replyMessagesObjRef.current = [];
    }
  }, [currentMessagesToTypeIndex]);

  function addTypedMessageToExistingMessages(index) {
    if (
      replyMessagesObjRef.current.length > 0 &&
      index < replyMessagesObjRef.current.length &&
      index >= 0
    ) {
      let existingMessage = exisitngMessagesRef.current;
      let newMessage = replyMessagesObjRef.current[index];
      setMessages([...existingMessage, newMessage]);
    }
  }

  useEffect(() => {
    let currentDate = moment().format("dddd MMM DD, YYYY");
    if (messagesGroupedByDate.length > 0 && isAITyping) {
      let currentDateIndex = messagesGroupedByDate.findIndex((m) => {
        return m.field == currentDate;
      });

      if (currentDateIndex >= 0) {
        currentDate = "";
      }
    }

    setCurrentDateString(currentDate);
  }, [messagesGroupedByDate, isAITyping]);

  function sendMessage(inputMsg) {
    if (inputMsg.length > 0) {
      setIsWaitingForReply(true);

      let currentMsg = [...exisitngMessagesRef.current];

      let msgObj = {
        message: inputMsg,
        senderUserId: patientInfo.userId
      };

      if (currentMsg.length > 0) {
        saveMessages([inputMsg], messageTypes.sent);
        currentMsg.push(msgObj);
        setMessages([...currentMsg]);
        setTimeout(scrollToBottom, 50);
      }

      let formData = {
        msg: inputMsg
      };

      setIsProcessing(true);

      sendMessageToCICI_Ajax(
        formData,
        function (response) {
          if (!isEmptyArray(response)) {
            let nonEmptyMsg = response.filter((m) => m.length > 0);
            saveMessages(nonEmptyMsg, messageTypes.received);
          } else if (typeof response == "string") {
            saveMessages([response], messageTypes.received);
          }
          setIsProcessing(false);
          setIsWaitingForReply(false);
        },
        function (err) {
          handleApiErrors(err);
          setIsProcessing(false);
          setIsWaitingForReply(false);
        }
      );
    }
  }

  function handleScrollToTop(event) {
    if (event && event.target) {
      let element = event.target;

      if (element.scrollTop == 0 && chatRoomId > 0) {
        getAIChatMessages(chatRoomId);
      }
    }
  }

  function onBeforeUnload() {
    stopSpeaking();
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      stopSpeaking();
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  function playBtnClicked(message, messageId) {
    if (message) {
      startSpeaking(
        message,
        function () {
          setPlayingMsgId(messageId);
        },
        function () {
          setPlayingMsgId(0);
        }
      );
    }
  }

  function pauseBtnClicked() {
    stopSpeaking(function () {
      setPlayingMsgId(0);
    });
  }

  function isMsgPlaying(messageId, playingMsgId) {
    return messageId == playingMsgId;
  }

  function renderMessages(messages) {
    return messages.map((message, index) => {
      if (message.type == messageTypes.received) {
        return (
          <>
            <ReceivedMessage
              key={message + index}
              message={message?.message}
              userImg={message?.userImg}
              isPlaying={isMsgPlaying(message.aiChatMessageId, playingMsgId)}
              playBtnClicked={() => {
                playBtnClicked(message.message, message.aiChatMessageId);
              }}
              pauseBtnClicked={pauseBtnClicked}
            />
          </>
        );
      } else {
        return (
          <>
            <SentMessage
              key={message + index}
              message={message?.message}
              userImg={message?.userImg}
              senderName={patientInfo.fullName}
            />
          </>
        );
      }
    });
  }

  function onTypingCompleted() {
    addTypedMessageToExistingMessages(currentMessagesToTypeIndex);
    setCurrentMesssagesToTypeIndex((value) => value + 1);
  }

  function renderAIMessageTypingSection() {
    return (
      <>
        {isAITyping ? (
          <>
            {currentDateString ? (
              <div className="chat-date text-center text-muted fst-italic mb-3 opacity-75">
                <span>{currentDateString}</span>
              </div>
            ) : (
              <></>
            )}
            <div className="chatbox">
              <img
                src={userImgPlaceholder}
                className="author-img rounded-circle"
              />
              <div className="chat-msg msg-output" style={{ display: "table" }}>
                <ReactTyped
                  strings={[currentMessage]}
                  showCursor={false}
                  typeSpeed={IntervalTimes.MS_25}
                  onComplete={onTypingCompleted}
                  fadeOut={true}
                  contentType="html"
                ></ReactTyped>
              </div>
              <br />
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      <section className="sec-profile-setting py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div class="gth-main-header d-flex justify-content-between align-items-center mb-3">
                <h1>Chat with CICI</h1>
              </div>
              <div className="bg-white border-radius-xlg p-4">
                <div className="chatbox-wrapper py-2">
                  <div className="chatbox-body">
                    <div id="container">
                      <div
                        ref={chatWrapperRef}
                        id="chat-wrapper"
                        onScroll={handleScrollToTop}
                      >
                        <div id="main-container" ref={chatListDivRef}>
                          {!isEmptyArray(messagesGroupedByDate) &&
                            messagesGroupedByDate.map((message, index) => {
                              return (
                                <div
                                  key={message?.field + index}
                                  className="chat-date-wrapper"
                                >
                                  <div className="chat-date text-center text-muted fst-italic mb-3 opacity-75">
                                    <span>{message?.field}</span>
                                  </div>
                                  {renderMessages(message.groupList)}
                                </div>
                              );
                            })}
                          {isWaitingForReply ? (
                            <div className="chatbox loading-animation">
                              <img
                                src={userImgPlaceholder}
                                className="author-img"
                              />
                              <div className="chat-msg msg-output">
                                <div className="messages__item messages__item--typing">
                                  <span className="messages__dot" />
                                  <span className="messages__dot" />
                                  <span className="messages__dot" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {renderAIMessageTypingSection()}
                        </div>
                      </div>
                      <MessageBox
                        isWaitingForReply={isWaitingForReply}
                        isProcessing={isProcessing}
                        sendMessage={sendMessage}
                        isAITyping={isAITyping}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatSection;
