import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { Shades } from "../../constants";
import { getImageBasedOnShade_Ajax } from "../../helpers/requests";
import ShadeOwlCarousel from "./ShadeOwlCarousel";
import Shade1Image from "../../assets/images/shade/shade_1_Manual_interaction_demo1.png";
import Shade2Image from "../../assets/images/shade/shade_2_Manual_interaction_demo1.png";
import Shade3Image from "../../assets/images/shade/shade_3_Manual_interaction_demo1.png";
import Shade4Image from "../../assets/images/shade/shade_4_Manual_interaction_demo1.png";
import Shade5Image from "../../assets/images/shade/shade_5_Manual_interaction_demo1.png";
import Shade6Image from "../../assets/images/shade/shade_6_Manual_interaction_demo1.png";
import Shade7Image from "../../assets/images/shade/shade_7_Manual_interaction_demo1.png";
import Shade8Image from "../../assets/images/shade/shade_8_Manual_interaction_demo1.png";
import Shade9Image from "../../assets/images/shade/shade_9_Manual_interaction_demo1.png";
import Shade10Image from "../../assets/images/shade/shade_10_Manual_interaction_demo1.png";

const ShadeSelector = (props) => {
  const { whitnessScoreReport, onSeeADentistNowButtonClicked } = props;
  const [currentShadeId, setCurrentShadeId] = useState(-1);
  const [currentShadeColor, setCurrentShadeColor] = useState("");
  const [whitnessReportShadeColor, setWhitnessReportShadeColor] = useState("");
  const [imageAfterApplyingShade, setImageAfterApplyingShade] = useState("");
  const isDemoShadeSelectorEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isDemoShadeSelectorEnabled
  );

  const dispatch = useDispatch();
  const prevSelShadeColor = useRef();

  const onHandleShadeClick = (id) => {
    setCurrentShadeId(id);
    setCurrentShadeColor(Shades[id].color);
  };

  const getImageBasedOnShade = () => {
    dispatch(showLoadingSpinner());
    URL.revokeObjectURL(imageAfterApplyingShade);
    setImageAfterApplyingShade("");

    getImageBasedOnShade_Ajax(
      whitnessScoreReport?.smartScanId,
      currentShadeColor?.substring(1),
      async function (response) {
        dispatch(hideLoadingSpinner());
        const url = URL.createObjectURL(response); // Create an object URL from the Blob
        setImageAfterApplyingShade(url);
      },
      function (err) {
        dispatch(hideLoadingSpinner());
      }
    );
  };

  useEffect(() => {
    if (whitnessScoreReport) {
      let curShade = Shades.find(
        (x) => x.score == whitnessScoreReport.shadeScore
      );
      setCurrentShadeId(curShade?.id);
      setCurrentShadeColor(curShade?.color);
      setWhitnessReportShadeColor(curShade?.color);
    }
  }, []);

  function getDemoShadeImage() {
    let shadeScore = Shades.find((x) => x.color == currentShadeColor)?.score;

    switch (shadeScore) {
      case 1:
        return Shade1Image;
      case 2:
        return Shade2Image;
      case 3:
        return Shade3Image;
      case 4:
        return Shade4Image;
      case 5:
        return Shade5Image;
      case 6:
        return Shade6Image;
      case 7:
        return Shade7Image;
      case 8:
        return Shade8Image;
      case 9:
        return Shade9Image;
      case 10:
        return Shade10Image;
      default:
        return "";
    }
  }

  useEffect(() => {
    if (prevSelShadeColor.current != currentShadeColor && currentShadeColor) {
      if (!isDemoShadeSelectorEnabled) {
        getImageBasedOnShade();
      } else {
        setImageAfterApplyingShade(getDemoShadeImage());
      }
      prevSelShadeColor.current = currentShadeColor;
    }
  }, [currentShadeColor]);

  return (
    <div className="col-12 mt-2 mt-md-4">
      <ShadeOwlCarousel
        currentShadeId={currentShadeId}
        shadeScore={whitnessScoreReport?.shadeScore}
        onHandleShadeClick={onHandleShadeClick}
      />
      <div className="row flex-row-reverse align-items-center mb-3 mt-3">
        <div className="col-md-5">
          <div className="row tooth-comparision mb-4 mb-md-0">
            <div className="col-6">
              <div className="broken-column">
                <figure className="mb-0">
                  <img
                    className="w-100 object-cover"
                    src={whitnessScoreReport.photoId}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </figure>
                <div
                  className="shade"
                  style={{
                    backgroundColor: whitnessReportShadeColor
                  }}
                >
                  Current Shade {whitnessScoreReport.shadeScore}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="broken-column item" style={{ display: "block" }}>
                <figure className="mb-0">
                  <img
                    className="w-100 object-cover"
                    src={imageAfterApplyingShade}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </figure>
                {currentShadeId >= 0 && (
                  <div
                    className="shade"
                    style={{ background: Shades[currentShadeId].color }}
                  >
                    Shade {Shades[currentShadeId].score}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 pe-md-5">
          <h3 className="text-muted11 lh-base mb-3 mb-md-4">
            Track your progress each month during your treatment until you reach
            your desired score.
          </h3>
          <button
            className="btn btn-secondary mb-3"
            onClick={onSeeADentistNowButtonClicked}
          >
            See a Dentist Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShadeSelector;
