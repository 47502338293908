export const isTextToSpeechSupported = () => {
  if ("speechSynthesis" in window) {
    return true;
  } else {
    console.log("Your browser does not support text-to-speech.");
    return false;
  }
};

export const startSpeaking = (text, startCallback, onEndCallback) => {
  if (text && isTextToSpeechSupported()) {
    stopSpeaking();

    var speech = new SpeechSynthesisUtterance(text);
    speech.lang = "en-US";

    speech.onstart = () => {
      if (startCallback) {
        startCallback();
      }
    };

    speech.onend = () => {
      if (onEndCallback) {
        onEndCallback();
      }
    };

    window.speechSynthesis.speak(speech);
  }
};

export const stopSpeaking = (onCancel) => {
  if (isTextToSpeechSupported() && isSpeaking()) {
    window.speechSynthesis.cancel();
    if (onCancel) {
      onCancel();
    }
  }
};

export const isSpeaking = () => {
  if (isTextToSpeechSupported() && window?.speechSynthesis?.speaking) {
    return window.speechSynthesis.speaking;
  }
  return false;
};
