import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Link,
  useNavigate,
  useLocation,
  createSearchParams
} from "react-router-dom";

import {
  SignUpFormFields,
  Patterns,
  GenderOptions,
  CountryCodes,
  DateTimeFormats,
  InsuranceCaptureOptionValues,
  ValidationTypes,
  SignupPageMetaDescription
} from "../../constants";
import {
  getStates_Ajax,
  checkIfUserExists_Ajax,
  validateEmail_Ajax
} from "../../helpers/requests";
import InputMask from "react-input-mask";
import {
  handleApiErrors,
  showAlertDialouge,
  validateDateOfBirth,
  validatePhoneNumber,
  isValidDate,
  formatDateTime,
  queryStringToJSON
} from "../../helpers/utils";
import $ from "jquery";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import Select from "react-select";
import DateInputMask from "../DateInput/DateInputMask";

export default function SignUpForm(props) {
  const [states, setStates] = useState([]);
  const [insurancePrefillData, setInsurancePrefillData] = useState([]);
  const externalPatId = useSelector((state) => state?.externalPatient?.patId);
  const employerId = useSelector((state) => state?.externalPatient?.employerId);
  const isInsuranceCaptureOptional = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.insuranceCaptureOptionalOnRegistration
  );
  const shouldShowSignUpInstructionVerbiage = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.showSignUpPageInstructionVerbiage
  );
  const isRegistrationBasedOnTypeEnabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.isRegistrationBasedOnTypeEnabled
  );
  const showConsentFormInRegistrationFlow = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.showConsentFormInRegistrationFlow
  );
  const excludeDependentPatientAgeLimitCheck = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.excludeDependentPatientAgeLimitCheck
  );
  const [type, setType] = useState(null);
  const [registrationType, setRegistrationType] = useState(null);
  const location = useLocation();
  const backFromNext = location?.state?.backFromNext
    ? location?.state?.backFromNext
    : false;
  const prevPatientData = location?.state?.patientData;

  const isInsuranceCaptureAlwaysEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.insuranceCaptureAlwaysEnabled
  );

  const isSmartScanFeatureAvailable = useSelector(
    (state) => state?.appSettings?.settingsData?.isSmartScanFeatureAvailable
  );

  function checkForQueryParams() {
    let params = queryStringToJSON();
    if (params) {
      if (params.type) {
        setType(params.type);
      }
      if (params.registrationtype && isRegistrationBasedOnTypeEnabled) {
        setRegistrationType(params.registrationtype);
      }
    }
  }

  function getInsuranceCaptureOptionValue() {
    if (isInsuranceCaptureAlwaysEnabled || isInsuranceCaptureOptional) {
      return InsuranceCaptureOptionValues.Yes;
    } else {
      return InsuranceCaptureOptionValues.No;
    }
  }

  const insuranceCaptureOptionValue = getInsuranceCaptureOptionValue();
  let { isSignUpExternalPatient, patientData } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      insurance: insuranceCaptureOptionValue
    }
  });

  const initGoogleTranslateElement = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
      },
      "google_translate_element"
    );
    function changeGoogleStyles() {
      let iframeElement = $('iframe');
      let iframeBody = iframeElement?.contents().find('body');
      let container = iframeElement?.contents().find('.VIpgJd-ZVi9od-vH1Gmf');
      if (iframeElement.length && iframeBody.length && container.length) {
          
          let margin = 2;
          let width = window.screen.width - 10;
          container.css({
              'overflow': 'scroll',
              'height': '270px',
              'width': width + 'px'
          });
          iframeBody.css({ 'overflow': '' });
          iframeElement.css({ 'left': margin + 'px' });
      }
      if ($(".goog-te-menu-frame").contents().find("body").length) {
        $(".goog-te-menu-frame").contents().find("body").css({
          "overflow-x": "auto",
          "box-sizing": "border-box"
        });
      } else {
        setTimeout(changeGoogleStyles, 50);
      }
    }
    changeGoogleStyles();
  };

  function handleTranslateClick(event) {
    if (
      event.target.id === "google_translate_element" ||
      event.target.closest("#google_translate_element")
    ) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    let googleTranslateElementScript = document.createElement("script");
    googleTranslateElementScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(googleTranslateElementScript);
    window.googleTranslateElementInit = initGoogleTranslateElement;
    document.addEventListener("click", handleTranslateClick);

    const prevTitle = document.title;
    document.title = "Dental.com | Sign Up";

    const metaDescription = document.querySelector('meta[name="description"]');
    
    if (metaDescription) {
      metaDescription.setAttribute('content', SignupPageMetaDescription);
    }

    return () => {
      document.removeEventListener(handleTranslateClick);
      document.title = prevTitle;
    };
  }, []);

  useEffect(() => {
    checkForQueryParams();
    let countryCode = CountryCodes.US;

    if (countryCode) {
      getStates(countryCode);
    }
  }, []);

  useEffect(() => {
    if (isSignUpExternalPatient && !backFromNext) {
      populateFromlData(patientData);
    }
  }, [isSignUpExternalPatient]);

  useEffect(() => {
    if (backFromNext) {
      prevPatientData.patientInsurance = location?.state?.insurancePrefillData;
      isSignUpExternalPatient = location?.state?.isSignUpExternalPatient;
      populateFromlData(prevPatientData);
    }
  }, [backFromNext]);

  useEffect(() => {
    setValue(SignUpFormFields.DenticonPatientId, externalPatId);
  }, [externalPatId]);

  useEffect(() => {
    setValue(SignUpFormFields.EmployerId, employerId);
  }, [employerId]);

  function getStates(countryCode) {
    if (countryCode) {
      getStates_Ajax(
        countryCode,
        function (response) {
          if (response && response.success && response.data) {
            let stateOptions = [];

            response.data.forEach((state) => {
              stateOptions.push({
                value: state.value,
                label: state.name
              });
            });

            setStates(stateOptions);
          }
        },
        function (err) {}
      );
    }
  }

  function populateFromlData(externalPatientData) {
    if (externalPatientData) {
      setValue(SignUpFormFields.FirstName, externalPatientData.firstName);
      setValue(SignUpFormFields.LastName, externalPatientData.lastName);
      if (externalPatientData.phone) {
        setValue(SignUpFormFields.Phone, externalPatientData.phone);
      }
      setValue(SignUpFormFields.Email, externalPatientData.email);
      setValue(SignUpFormFields.State, externalPatientData.state);
      setValue(SignUpFormFields.Gender, externalPatientData.gender);
      setValue(
        SignUpFormFields.IsSubscribedForSms,
        externalPatientData.isSubscribedForSms
      );
      if (isValidDate(externalPatientData.dateOfBirth)) {
        setValue(
          SignUpFormFields.DateOfBirth,
          formatDateTime(
            externalPatientData.dateOfBirth,
            DateTimeFormats.MM_DD_YYYY
          )
        );
      } else {
        setValue(SignUpFormFields.DateOfBirth, "");
      }
      if (isInsuranceCaptureAlwaysEnabled || isInsuranceCaptureOptional) {
        setInsurancePrefillData(externalPatientData.patientInsurance);
      }
    }
  }

  function goToNextPage(signUpFormData) {
    let state = {};
    let url = "";
    const params = queryStringToJSON();

    if (signUpFormData.insurance === InsuranceCaptureOptionValues.Yes) {
      url = "/insurance";
      if (type) {
        params.type = type;
      }
      if (registrationType) {
        params.registrationType = registrationType;
      }
      state = {
        signUpFormData,
        insurancePrefillData,
        isSignUpExternalPatient,
        from: "/sign-up"
      };
    } else if (showConsentFormInRegistrationFlow) {
      url = "/registration-consent-form";
      if (registrationType) {
        params.registrationType = registrationType;
      }
      state = {
        signUpFormData,
        isSignUpExternalPatient,
        from: "/sign-up"
      };
    } else {
      url = "/set-password";
      state = {
        signUpFormData,
        isSignUpExternalPatient,
        from: "/sign-up"
      };
    }

    navigate(
      {
        pathname: url,
        search: createSearchParams(params).toString()
      },
      { state: state }
    );
  }

  function returnToSignInPage() {
    const params = queryStringToJSON();
    if (type) {
      params.type = type;
    }
    if (registrationType) {
      params.registrationType = registrationType;
    }
    let url = "/login";
    navigate({
      pathname: url,
      search: createSearchParams(params).toString()
    });
  }

  function checkIfUserExists(jsonData, signUpFormData) {
    dispatch(showLoadingSpinner());
    checkIfUserExists_Ajax(
      JSON.stringify(jsonData),
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response && response.success && response.data) {
          if (response.data.userExists && response.data.loginURL) {
            showAlertDialouge(
              "Email already exists",
              <>
                An account with the provided email already exists. Please{" "}
                <a href={response.data.loginURL}>sign in</a> or try with another
                email
              </>
            );
          } else if (!response.data.userExists) {
            goToNextPage(signUpFormData);
          }
        } else if (response && !response.success && response.message) {
          showAlertDialouge("Error", response.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  const onSubmit = (signUpFormData) => {
    let jsonData = {
      email: signUpFormData.email
    };

    signUpFormData.Country = CountryCodes.US;
    dispatch(showLoadingSpinner());
    validateEmail_Ajax(
      JSON.stringify(jsonData),
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response?.success && response?.data && response.data.isValid) {
          if (isSignUpExternalPatient) {
            goToNextPage(signUpFormData);
          } else {
            checkIfUserExists(jsonData, signUpFormData);
          }
        } else if (response?.success && response?.data) {
          showAlertDialouge(
            "Error",
            "Invalid email address, please enter a valid email."
          );
        } else if (!response?.success && response?.message) {
          showAlertDialouge("Error", response.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  };

  function shouldShowInsuranceOptions() {
    if (isInsuranceCaptureAlwaysEnabled) {
      return false;
    }
    return isInsuranceCaptureOptional;
  }

  function getSmartScanRelatedVerbiage() {
    if (isSmartScanFeatureAvailable) {
      return " your use of Smart Scan or";
    }
    return "";
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="progress mb-3">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "33.33%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <form
            className="bg-white border-radius-xlg px-4 px-md-5 py-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h2 className="text-center text-violet">
              Creating an account is quick and easy
            </h2>

            <div className="text-muted text-center fs-5 mb-4">
              Already have an account?{" "}
              <span
                className="text-primary text-decoration-none111 hover-element"
                onClick={returnToSignInPage}
              >
                Sign In
              </span>
            </div>
            {shouldShowSignUpInstructionVerbiage && (
              <p
                className="text-center fs-5 mb-4"
                style={{ maxWidth: 875 + "px", margin: "auto" }}
              >
                Subscribers and adult dependents 18 and older should create
                their own accounts. You will provide subscriber insurance info
                on the next page. Subscribers can add dependents under 18 as
                patients after creating an account.
              </p>
            )}
            <div className="text-secondary fw-bold fs-5 mb-1">
              * All fields are required
            </div>

            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  First Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  {...register(SignUpFormFields.FirstName, {
                    required: "This field is required.",
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed."
                    }
                  })}
                />
                {errors.firstName?.message ? (
                  <p className="help-block">{errors.firstName.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  Last Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  {...register(SignUpFormFields.LastName, {
                    required: "This field is required.",
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed."
                    }
                  })}
                />
                {errors.lastName?.message ? (
                  <p className="help-block">{errors.lastName.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  Date of Birth<span className="text-danger">*</span>
                </label>
                <DateInputMask
                  register={register}
                  fieldName={SignUpFormFields.DateOfBirth}
                  errors={errors}
                  validationType={ValidationTypes.DateOfBirth}
                  excludeDependentPatientAgeLimitCheck={
                    excludeDependentPatientAgeLimitCheck
                  }
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  Phone Number (XXX)-XXX-XXXX
                  <span className="text-danger">*</span>
                </label>
                <InputMask
                  mask="(999)-999-9999"
                  className="form-control"
                  placeholder="(XXX)-XXX-XXXX"
                  {...register(SignUpFormFields.Phone, {
                    required: "This field is required.",
                    min: 14,
                    validate: (value) => validatePhoneNumber(value)
                  })}
                />
                {errors.phone?.message ? (
                  <p className="help-block">{errors.phone.message}</p>
                ) : (
                  <></>
                )}
                <div className="form-check mt-2 small-form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="saveBillingInfo"
                    id="saveBillingInfo1"
                    {...register(SignUpFormFields.IsSubscribedForSms, {
                      required: "This checkbox is required."
                    })}
                  />
                  <label
                    className="form-check-label fs-12 text-muted mt-1 d-inline-block w-100"
                    for="saveBillingInfo1"
                  >
                    By providing your phone number, you agree to receive text
                    messages from dental.com or Teledentix, relating to
                    {getSmartScanRelatedVerbiage()} your Teledentistry
                    Consultation. You may opt-out at any time.
                    <span className="text-danger">*</span>
                  </label>
                  {errors.isSubscribedForSms?.message ? (
                    <p className="help-block">
                      {errors.isSubscribedForSms.message}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  {...register(SignUpFormFields.Email, {
                    required: "This field is required.",
                    pattern: {
                      value: Patterns.Email,
                      message:
                        "Please enter a valid email address. (e.g. example@email.com)"
                    }
                  })}
                />
                {errors.email?.message ? (
                  <p className="help-block">{errors.email.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  State<span className="text-danger">*</span>
                </label>
                <Controller
                  control={control}
                  name={SignUpFormFields.State}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="react-select"
                      placeholder="Select a state"
                      options={states}
                      onChange={(state) => field.onChange(state.value)}
                      value={states.find(
                        (option) => option.value === field.value
                      )}
                      menuPlacement="auto"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#3fbbeb",
                          primary: "#3fbbeb"
                        }
                      })}
                    />
                  )}
                  rules={{
                    required: "This field is required."
                  }}
                />
                {errors.state?.message ? (
                  <p className="help-block">{errors.state.message}</p>
                ) : (
                  <></>
                )}
              </div>
              {shouldShowInsuranceOptions() ? (
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="mb-3">
                      <div className="d-flex flex-wrap flex-sm-nowrap form-group justify-content-start">
                        <label className="fs-3 mb-1 me-1 w-sm-auto">
                          Do you have insurance?
                        </label>
                        <div className="d-inline-block form-check me-4 ms-sm-auto">
                          <input
                            className="form-check-input insurance"
                            type="radio"
                            name="insurance"
                            id="insurance-yes"
                            value="insurance-yes"
                            {...register(SignUpFormFields.Insurance, {
                              required: true
                            })}
                          />
                          <label
                            className="form-check-label fs-4"
                            htmlFor="insurance-yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check d-inline-block">
                          <input
                            className="form-check-input insurance"
                            type="radio"
                            name="insurance"
                            id="insurance-no"
                            value="insurance-no"
                            {...register(SignUpFormFields.Insurance, {
                              required: true
                            })}
                          />
                          <label
                            className="form-check-label fs-4"
                            htmlFor="insurance-no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <button
                type="submit"
                className="btn btn-secondary btn-rounded btn-lg px-5"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
      <div id="google_translate_element"></div>
    </div>
  );
}
